<template>
  <div>
    <PageHeader
      :main-header="$t('common.not-found')"
      main-header-color="grey"
      main-header-text-color="white--text"
    />
    <div class="d-flex justify-center">
      <v-btn text large color="primary" to="/">{{
        $t('common.back-to-home')
      }}</v-btn>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
export default {
  name: 'NotFound',

  components: {
    PageHeader
  }
}
</script>

<style scoped></style>
